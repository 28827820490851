import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from "gatsby-image"
import styled from "styled-components"

export default class CompactPostList extends React.Component {
  render() {
    const { posts, title, grillImg } = this.props

    const PostCard = styled.div`
      height: 100%;
      transition: 300ms box-shadow;

      &:hover, &:focus {
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
      }
    `


    const Image = styled(Img)`
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    `

    return (
      <section className="section">
        <div className="container">
          <div className="content">
            <h1 className="has-text-weight-bold is-size-2 has-text-centered">{title}</h1>
          </div>
          <div className="columns is-multiline">
            {posts.map(({ node: post }) => (
              <div
                className="column is-3"
                key={`${post.id}-post`}
              >
                <Link className='has-text-primary' to={`/${post.slug}`}>
                  <PostCard className="card">
                    <div className="card-image">
                      <figure className="image is-2by1">
                        {post.featured_media && (
                          <Image fluid={post.featured_media.localFile.childImageSharp.fluid} style={{position: 'absolute'}} />
                        )}
                        {!post.featured_media && (
                          <Image fluid={grillImg} style={{position: 'absolute'}} />
                        )}
                      </figure>
                    </div>
                    <div className="card-content">
                      {post.title}
                    </div>
                  </PostCard>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  }
}

CompactPostList.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}
