import React from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'
import styled from 'styled-components'

import Layout from '../components/Layout'
import CompactPostList from '../components/CompactPostList'

const Home = ({ data }) => {
  const { wordpressPage: homepage, posts } = data
  const { title: siteTitle } = data.site.siteMetadata
  const { fluid: grillImg } = data.grillImg.childImageSharp

  const Hero = styled.div`
    position: relative;
    height: 0;
    top: 50px;
    padding-bottom: 60%;
    margin-bottom: 50px;

    @media (min-width: 600px) {
      padding-bottom: 30%;
    }

    &:before {
      content: '';
      width: 100%;
      height: 150px;
      position: absolute;
      bottom: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), transparent);
    }
  `

  const Heading = styled.h1`
    color: #fff !important;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;

    @media (min-width: 850px) {
      top: 80%;
      width: auto;
    }
  `

  const Image = styled(Img)`
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  `

  const HomeHeading = styled.div`
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    font-size: 20px;
    padding: 30px 15px 0;
  `

  return (
    <Layout>
      <Helmet title={`Home | ${siteTitle}`} />
      <Hero>
        <Image
          fluid={homepage.featured_media.localFile.childImageSharp.fluid}
          style={{ position: `absolute` }}
        />
        <Heading
          className="title is-size-1"
          dangerouslySetInnerHTML={{ __html: homepage.title }}
        />
      </Hero>
      <HomeHeading
        dangerouslySetInnerHTML={{ __html: `<div> ${homepage.excerpt} </div>` }}
      />
      <CompactPostList
        posts={posts.edges}
        grillImg={grillImg}
        title="New &amp; Hot"
      />
      <div className="container has-text-centered m-b-xl">
        <Link to="/blog" className="button">
          See all posts
        </Link>
      </div>
    </Layout>
  )
}
export default Home

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wordpressPage(slug: { eq: "stereotype-steve" }) {
      id
      content
      title
      excerpt
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    posts: allWordpressPost(
      limit: 4
      sort: { fields: date, order: DESC }
      filter: { categories: { elemMatch: { slug: { ne: "steve-unplugged" } } } }
    ) {
      edges {
        node {
          id
          title
          date
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    grillImg: file(name: { eq: "grill" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
